<template>
  <v-sheet id="our-solution" color="transparent">
    <v-container class="py-4 py-lg-8 full-screen my-auto d-flex align-center">
      <div>
        <div class="text-center">
          <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Multi-night, In-Home Monitoring of Leg Movements</div>
          <h2 class="text-h3 text-lg-h2">Our Solution</h2>
          <v-responsive max-width="1200" class="mx-auto">
            <div class="text-h6 text-lg-h5 mt-4">Improving the diagnosis and treatment management of sleep-releated movement disorders requires augmenting the current clinical evaluations with diagnostic procedures to measure periodic leg movements during sleep (PLMS). Tanzen Medical is working to provide an end-to-end solution for gathering objective and subjective sleep measures.</div>
          </v-responsive>
        </div>
        <v-row class="mt-4">
          <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet
                  color="light"
                  rounded
                  elevation="1"
                  class="pa-2"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5 font-weight-bold">{{ item.title }}</div>
                <div class="text-h6 mt-1">{{ item.description }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-seat-legroom-extra',
        title: 'Leg Movement Classification',
        description: 'Using inertial measures (accelerometry, gyroscopy) and capacitive distance sensing, we determine clinically significant leg movements, classify PLMS using the WASM standards and determine their association with arousal.'
      },{
        icon: 'mdi-medical-bag',
        title: 'Clinical Sleep Metrics',
        description: 'Our core product deliver detection of disease biomarkers (LMS, PLMS and Arousal) as well as the standard metrics required to contextualize a nights sleep (sleep efficiency, in-bed times, sleep-times, WASO, etc.)'
      }, {
        icon: 'mdi-weather-night',
        title: 'Multi-Night, In-Home Recording',
        description: 'Night-to-night variability of sleep behavior presents a problem for in-lab PSG recording, often limited to a single night. We design our products for multi-night recording to ensure screening reliability.'
      }, {
        icon: 'mdi-notebook-edit-outline',
        title: 'Digital Sleep Diary',
        description: 'Tanzen is developing a sleep diary system, integrated into its web server and RestEaze mobile application, to include patient subjective measures in research studies and patient management.'
      }, {
        icon: 'mdi-monitor-dashboard',
        title: 'Physician Dashboard',
        description: 'Tanzen Medical\'s solution is end-to-end. Out website allows Physician to create and manage patient accounts, where the processed patient data is presented to Physicians for interpretation in detailed form.'
      }, {
        icon: 'mdi-account-multiple',
        title: 'Bi-Lateral Synchronization',
        description: 'Research and clinical accuracy is at the core of our products. We provide solutions that can be used for simple monolateral recording as well as more robust bilateral recording with multi-device synchronization.'
      }]
    }
  }
}
</script>

<style scoped>
.full-screen{
  min-height: calc(100vh - 60px);
}
</style>