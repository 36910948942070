<template>
  <v-sheet id="our-mission" color="transparent">
    <v-container class="py-4 py-lg-8 full-screen my-auto d-flex align-center">
      <v-row>
        <v-col cols="12" lg="6" offset-xl="1" xl="6">
          <h2 class="text-h3 text-lg-h1 mt-1 mt-lg- mt-xl-5">Our Mission</h2>
          <div class="text-h4 text-lg-h4 mt-6">The mission of Tanzen Medical is to <span class="primary--text">make the monitoring of leg movements during sleep accessible</span> for the entire population of physicians and patients. </div>
          <div class="text-h6 text-lg-h5 mt-4 font-weight-normal">Leg Movements during Sleep (LMS) are <span class="primary--text">biomarkers</span> for sleep movement disorder. LMS fragment sleep and if left untreated lead to <span class="primary--text">heart diseases, stroke, depression, and early death in millions of patients.</span></div>
          <!-- <v-btn color="primary" large class="mt-6">Explore demo</v-btn> -->
        </v-col>
        <v-col cols="12" lg="6" xl="4">
          <v-sheet elevation="6" class="pa-2" rounded>
            <v-carousel
              v-model="model"
              :show-arrows="false"
              cycle
              height="420"
              hide-delimiter-background
              :light="!$vuetify.theme.isDark"
            >
              <v-carousel-item v-for="(item, i) in carousel" :key="i">
                <v-sheet>
                  <v-responsive max-width="400" class="mx-auto text-center">
                    <div class="text-h4 mt-8 font-weight-bold">{{ item.title }}</div>
                    <div class="text-body-1 mt-5">{{ item.text }}</div>
                    <div class="mt-4">
                      <!-- <router-link :to="item.link.to" class="text-decoration-none font-weight-bold text-h6">{{ item.link.title }}</router-link> -->
                    </div>
                  </v-responsive>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    model: 0,
    carousel: [{
      title: 'Aid in the diagnosis and treatment management of sleep movement disorders',
      text: 'Provide an objective measurement tool for sleep movement disorders, enabling a 70% improvement in diagnosis accuracy and an 80% improvement in treatment management compared to the current standard of care.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Identify sleep disorders in pediatric ADHD patients',
      text: 'Empower pediatricians, psychiatrists, and parents to correctly identify and manage treatable sleep disorders in 70% children suspected of ADHD and improve outcomes for these children.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Enable new research into LMS',
      text: 'Provide new tools and methods for scientists and other researchers to discover novel insights about LMS and their relationship with health, wellness, and performance.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }]
  })
}
</script>

<style scoped>
.full-screen{
  min-height: calc(100vh - 60px);
}
</style>