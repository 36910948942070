<template>
  <v-main color="transparent">
    <Toolbar />
    <div>
      <Hero />
      <Introduction />
      <OurMission />
      <UnmetMedicalNeed />
      <OurSolution />
    </div>
    <Footer />
  </v-main>
</template>

<script>
import Toolbar from '@/components/header/Toolbar.vue'
import Hero from '@/components/home/Hero.vue'
import Introduction from '@/components/home/Introduction.vue'
import UnmetMedicalNeed from '@/components/home/UnmetMedicalNeed.vue'
import OurMission from '@/components/home/OurMission.vue'
import OurSolution from '@/components/home/OurSolution.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  components: {
    Hero,
    Toolbar,
    Introduction,
    UnmetMedicalNeed,
    OurMission,
    OurSolution,
    Footer
  }
}
</script>
