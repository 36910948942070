<template>
  <v-sheet id="medical-need">
    <v-container class="py-4 py-lg-8 full-screen my-auto d-flex align-center" >
      <div>
        <div class="text-center">
          <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Why Monitor Leg Movements during Sleep?</div>
          <h2 class="text-h2 text-lg-h1">Unmet Medical Need</h2>
          <v-responsive max-width="1200" class="mx-auto">
            <div class="text-h6 text-lg-h5 mt-4">The monitoring of leg movements during sleep is critical for the diagnosis and management of many sleep-related disorders, but is not practiced outside of expensive sleep studies.
            </div>
          </v-responsive>
        </div>
        <v-row class="mt-6">
          <v-col
            v-for="(item, i) in stats"
            :key="i"
            cols="12"
            md="6"
            lg="3"
            class="text-center"
          >
            <div class="text-h2 text-md-h3 text-lg-h2 text-xl-h1">{{ item.value }}</div>
            <div class="text-h6 text-lg-h5 pt-2">{{ item.title }}</div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      stats: [{
        title: 'LMS are related to sleep movement disorders including restless leg syndrome (RLS) and periodic leg movement disorder (PLMD) in ≥ 46M people in the U.S.',
        value: '46M'
      }, {
        title: 'The standard of care to measure LMS are sleep studies - polysomnography (PSG) - can cost $600 - $5000 per night, and require invasive instrumentation, limiting their use and preventing long-term treatment monitoring.',
        value: '$600-$5K'
      }, {
        title: 'The limited ability to measure LMS results in misdiagnosis. 86-98% of RLS/PLMD patients are misdiagnosed. Up to 45M patients suffer from unresolved sleep disturbances.',
        value: '86-98%'
      }, {
        title: 'Up to 70% of 6M children in the U.S. treated for ADHD have a sleep disorder associated with LMS that cause ADHD symptoms. Treating the underlying sleep disorder may avoid the need for treatment using stimulants.',
        value: '4.2M'
      }]
    }
  }
}
</script>

<style scoped>
.full-screen{
  min-height: calc(100vh - 60px);
}
</style>