<template>
  <v-container id="home-intro" fluid class="d-flex flex-column full-screen">
    <v-row class="my-0">
      <v-col
        cols="12"
        md="12"
        lg="7"
        xl="6"
        class="order-12 order-lg-1 px-2 px-sm-4 pl-lg-12"
        style="margin: auto;"
      >
        <h1 class="d-none text-h3 text-lg-h1 text-xl-h1"><span class="primary--text">Tanzen Medical</span></h1>
        <h1 class="text-h4 text-sm-h3 text-lg-h2 text-xl-h1 mt-lg-2 mt-xl-2">Improving the diagnosis and treatment of <span class="primary--text"> sleep movement disorders</span></h1>
        <h2 class="text-body-1 text-sm-h5 mt-4 secondary--text text--darken-1">We aim to improve diagnosis and treatment management of sleep disorders through in-home <b>characterization of leg movement during sleep </b>and their relationships with:</h2>
        <ul class="text-body-1 text-sm-h5 mt-2 secondary--text text--darken-1">
          <li>Periodic Leg Movements</li>
          <li>Cortical Arousal</li>
          <li>Autonomic Arousal</li>
        </ul>
        <div class="mt-4 mt-xl-10">
          <v-btn x-large class="my-1 mx-sm-1 w-full w-sm-auto" color="primary" to="/contact">Contact Us</v-btn>
          <v-btn x-large class="my-1 mx-sm-1 w-full w-sm-auto" to="/products">Learn more</v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
        lg="5"
        xl="6"
        class="d-block order-1 order-lg-12 p-12 pr-lg-12 text-center" 
        style="margin: auto;"
      >
        <v-img
          :src="require('@/assets/images/Tanzen-Logo-Large.png')" 
          :max-height="$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 60px)' : '800'"
          :max-width="$vuetify.breakpoint.lgAndUp ? '50vw' : '550px'"
          style="margin: auto;"
          alt=""
        ></v-img>
      </v-col>
    </v-row>
    <div class="d-flex">
      <v-divider class="my-1"></v-divider>
      <div class="mx-3 overline">{{ title }}</div>
      <v-divider class="my-1"></v-divider>
    </div>
    <div class="text-center my-1">
      <a
        v-for="(logo, index) in logos"
        :key="index"
        :href="logo.link"
      >
        <img :src="logo.image" class="logo"/>
      </a>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Our Partners & Customers'
    }
  },
  data() {
    return {
      logos: [{
        image: require('@/assets/images/logos/umbc.svg'),
        link: '#'
      }, {
        image: require('@/assets/images/logos/jhu.png'),
        link: '#'
      }, {
        image: require('@/assets/images/logos/twinery.png'),
        link: '#'
      }, {
        image: require('@/assets/images/logos/arl.png'),
        link: '#'
      }, {
        image: require('@/assets/images/logos/ut.png'),
        link: '#'
      }, {
        image: require('@/assets/images/logos/yale2.png'),
        link: '#'
      }]
    }
  }
}
</script>

<style scoped>
.full-screen{
  min-height: calc(100vh - 60px);
}

.logo {
  filter: grayscale(100%) brightness(1.1);
  /* width: 180px; */
  max-width: 150px;
  max-height: 70px;
  padding: 15px;
  vertical-align: middle;
}

.theme--dark img {
  filter: grayscale(100%) invert(1);
}

img:hover {
  filter: none;
}
</style>
